<template>
  <div class="login-wrapper">
    <div class="login-form">
      <div class="login-title">
        <h4>{{ $store.getters.getWebSetting.projectName }}</h4>
      </div>
      <el-form :model="formData" :rules="formRules" ref="formRef" size="large">
        <el-form-item prop="username">
          <el-input v-model="formData.username" placeholder="请输入账户名称" autocomplete="off">
            <template #suffix>
              <el-icon>
                <User />
              </el-icon>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="formData.password" placeholder="请输入账户密码" autocomplete="off" show-password
            @keyup.enter="loginEvent">
            <template #suffix>
              <el-icon>
                <Lock />
              </el-icon>
            </template>
          </el-input>
        </el-form-item>
        <!-- <el-form-item prop="vercode">
          <el-input
            v-model="formData.vercode"
            placeholder="验证码"
            prefix-icon="el-icon-star-off"
            autocomplete="off"
          ></el-input>
        </el-form-item> -->
        <div class="login-footer">
          <el-button type="primary" class="login-btn" @click="loginEvent()">
            <el-icon v-if="loadStatus" class="el-icon-loading">
              <Loading />
            </el-icon>
            登 录
          </el-button>
        </div>
      </el-form>
    </div>

    <div class="login-copyright">
      Copyright © 2023 {{ $store.getters.getWebSetting.projectName }} All rights reserved.
      <br>
      <a href="https://beian.miit.gov.cn" target="_blank">备案号:粤ICP备2022048005号</a>
    </div>
  </div>
</template>
<script>
import { login } from "@/api/login";
// import { ElMessage } from "element-plus";
import { ElMessage } from "element-plus/lib/components"
//import store from "@/store";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      formData: {
        client_id: "center",
        client_secret: "center.secret",
        grant_type: "password",
        username: "",
        password: "",
      },
      formRules: {
        username: [
          { required: true, message: "请输入账户名称", trigger: "blur" },
          { min: 1, max: 50, message: "长度在1-50之间", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入账户密码", trigger: "blur" },
          { min: 1, max: 50, message: "长度在1-30之间", trigger: "blur" },
        ],
      },
      loadStatus: false,
      redirectUrl: null
    };
  },
  methods: {
    loginEvent() {
      let that = this;
      this.$refs.formRef.validate((valid) => {
        if (!valid) return;

        this.loadStatus = true;
        login(this.formData).then(
          function (res) {
            ElMessage.success("登录成功");

            let simplePwd = false;
            if (that.formData.password.length < 6 || that.formData.password.indexOf("123") > -1
              || that.formData.password.indexOf("abc") > -1)
              simplePwd = true;

            that.$router.push(that.redirectUrl || "/" + (simplePwd ? "?type=simple" : ""));
          },
          function (res) {
            that.loadStatus = false;
            console.log("error", res);
            switch (res.error) {
              case "invalid_grant":
                ElMessage.error("账户或密码错误");
                break;
              default:
                ElMessage.error(res.error_description || "网络错误请稍后再试");
                break;
            }
          }
        );
      });
    },
  },
  mounted() {
    //let baseUrl = process.env.VUE_APP_BASEURL;
    this.redirectUrl = this.$route.query.redirectUrl;
    console.log('login.redirectUrl',this.redirectUrl);
  },
};
</script>
<style scoped>
.login-wrapper {
  max-width: 100%;
  padding: 50px 20px;
  position: relative;
  box-sizing: border-box;
  background-image: url("~@/assets/login-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.login-form {
  margin: 0 auto;
  width: 360px;
  max-width: 100%;
  padding: 25px 30px;
  position: relative;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 4px;
}

@media screen and (min-height: 550px) {
  .login-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -250px;
  }
}

.login-title {
  text-align: center;
  margin: 20px auto;
}

.login-title h4 {
  font-size: 1.5em;
  font-weight: lighter;
}

.login-footer {
  text-align: center;
}

.login-btn {
  width: 100%;
}

.login-copyright {
  text-align: center;
  color: #eee;
  font-size: 1em;
  position: absolute;
  bottom: 20px;
  right: 0;
  left: 0;
}

.login-copyright a {
  color: #eee;
  font-size: 1em;
  display: block;
  margin-top: 5px;
}</style>